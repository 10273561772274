import { createTextStyle } from "src/utils/createTextStyle";
import { EFontWeight, EFonts } from "src/config/enums";
import styled from "styled-components";
import { fluidSizing } from "src/utils";

export const Heading = styled.h3`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Medium,
    [20, 25, 30],
    [24, 30, 36]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
`;
