import * as Styled from "./OxFeatureBlock.styled";

import {
  EAnimatedWrapperAnimation,
  OxAnimatedWrapper,
} from "../OxAnimatedWrapper";
import { OxCarousel, TOxCarouselItem } from "src/components/OxCarousel";
import React, { useState } from "react";

import { OxContainer, OxContainerGutter } from "src/components/OxContainer";
import { OxLineHeader } from "src/components/OxLineHeader";
import { OxTextTagline } from "src/components/OxTextTagline";
import { OxTextHeading } from "src/components/OxTextHeading";
import { OxTextParagraph } from "src/components/OxTextParagraph";
import { generateImgSizes } from "src/utils";

type TSliderItem = {
  label?: string;
  title: string;
  subtitle?: string;
  description: string;
  linkUrl: string;
  linkLabel?: string;
  carouselData: TOxCarouselItem;
};

type TProps = {
  lineHeaderTitle: string;
  sliderData: TSliderItem[];
};

export const OxFeatureBlock = ({
  lineHeaderTitle,
  sliderData = [],
}: TProps): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const imageSizes = generateImgSizes({
    values: OxContainerGutter,
    formatter: (val, breakpoint) =>
      `${(100 - val * 2) * (breakpoint === "xs" ? 1 : 0.6)}vw`,
  });

  return (
    <OxContainer>
      <OxLineHeader>{lineHeaderTitle}</OxLineHeader>
      <Styled.ContentContainer>
        <Styled.CarouselWrapper>
          <OxCarousel
            alts={sliderData.map((slide) => slide.title)}
            imageSizes={imageSizes}
            items={sliderData.map((item) => item.carouselData)}
            controlsPosition="bottom-inside"
            carouselOptions={{
              beforeChange: (_: number, nextSlide: number): void => {
                setCurrentSlide(nextSlide);
              },
            }}
            dots
            arrows
          />
        </Styled.CarouselWrapper>
        <Styled.Content>
          {sliderData.map((slide, index) => (
            <OxAnimatedWrapper
              key={index}
              relativeBox={true}
              animation={EAnimatedWrapperAnimation.FadeIn}
              active={currentSlide === index}
            >
              {slide.label && <OxTextTagline>{slide.label}</OxTextTagline>}
              <OxTextHeading>{slide.title}</OxTextHeading>
              {slide.subtitle && (
                <Styled.UpperParagraph>{slide.subtitle}</Styled.UpperParagraph>
              )}
              <OxTextParagraph>{slide.description}</OxTextParagraph>
              <Styled.Link to={slide.linkUrl}>{slide.linkLabel}</Styled.Link>
            </OxAnimatedWrapper>
          ))}
        </Styled.Content>
      </Styled.ContentContainer>
    </OxContainer>
  );
};
