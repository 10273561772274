import { EFonts, EFontWeight } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxLink } from "src/components/OxLink";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { TBasicTheme } from "src/types/theme.types";

const sharedStyles = ({ theme }: { theme: TBasicTheme }) => css`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  transition-duration: 400ms;

  &:after {
    left: 0;
    top: calc(50% - 2px);
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background: ${theme.colors.primary.main};
    ${fluidSizing([{ prop: "width", values: [24, 24, 24] }])}
  }

  &:before {
    left: 0;
    top: calc(50% - 2px);
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background: ${theme.colors.basic.black};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-out;
    ${fluidSizing([{ prop: "width", values: [24, 24, 24] }])}
    z-index: 1;
  }

  &:hover {
    color: ${theme.colors.basic.black};
    &:before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  ${fluidSizing([{ prop: "padding-left", values: [32, 32, 32] }])}

  span {
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [10, 10, 10],
      [16, 16, 16]
    )}
  }
`;

export const Container = styled(OxLink)(
  ({ theme }) => css`
    ${sharedStyles({ theme })}
  `
);

export const ContainerNonLink = styled.span(
  ({ theme }) => css`
    ${sharedStyles({ theme })}
  `
);
