import { EFontWeight, EFonts, ESizes } from "src/config/enums";
import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { OxLinkWithLine } from "src/components/OxLinkWithLine";
import { createTextStyle } from "src/utils/createTextStyle";

export const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
    }
  `
);

export const CarouselWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    ${theme.breakpoints.only("xs")} {
      margin-bottom: ${toVw(10, "xs")};
    }
    ${theme.breakpoints.up("s")} {
      width: 60%;
    }
  `
);

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
  ${fluidSizing([
    {
      prop: "padding-left",
      values: [null, ESizes.GutterS, ESizes.GutterM]
    },
    { prop: "padding-top", values: [null, 10, 10] },
    { prop: "padding-bottom", values: [null, 10, 10] }
  ])}
`;

export const UpperParagraph = styled.p`
  letter-spacing: -0.02em;
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Light,
    [14, 14, 16],
    [18, 18, 22]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
`;

export const Link = styled(OxLinkWithLine)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
  `
);
