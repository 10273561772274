import * as Styled from "./OxLinkWithLine.styled";

import React, { PropsWithChildren } from "react";

import { GatsbyLinkProps } from "gatsby";

type TProps = {
  asSpan?: boolean;
};

export const OxLinkWithLine = ({
  children,
  asSpan,
  ...props
}: SCProps<
  "input",
  PropsWithChildren<GatsbyLinkProps<TProps>>
>): JSX.Element => {
  const ContainerComponent = asSpan
    ? Styled.ContainerNonLink
    : Styled.Container;
  return (
    <ContainerComponent {...props}>
      <span>{children}</span>
    </ContainerComponent>
  );
};
