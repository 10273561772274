import { EFontWeight, EFonts, ESizes } from "src/config/enums";
import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { createTextStyle } from "src/utils/createTextStyle";

export const Container = styled.header(
  ({ theme }) => css`
    display: flex;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      align-items: flex-start;
    }
    ${theme.breakpoints.up("s")} {
      align-items: center;
      width: 100%;
    }
    ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
  `
);

export const Title = styled.div(
  ({ theme }) => css`
    flex-shrink: 0;
    position: relative;

    &::before {
      background: ${theme.colors.basic.black};
      content: "";
      height: 5px;
      position: absolute;
    }

    ${theme.breakpoints.only("xs")} {
      margin-bottom: ${toVw(6, "xs")};
      &::before {
        bottom: ${toVw(-6, "xs")};
        left: 0;
        min-width: ${`calc(50vw - ${toVw(28, "xs")})`};
      }
    }

    ${theme.breakpoints.up("s")} {
      &::before {
        bottom: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50vw;

        ${fluidSizing([
          {
            prop: "width",
            values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM],
            formatter: (val: number): string => `calc(50vw - ${val}vw)`
          }
        ])}
      }
    }
  `
);

export const Text = styled.h4(
  ({ theme }) =>
    css`
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [12, 16, 18],
        [14, 18, 20]
      )}
      ${fluidSizing([{ prop: "padding-right", values: [5, 10, 20] }])}
      background: ${theme.colors.basic.white};
      position: relative;
      text-transform: uppercase;
    `
);

export const Line = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.basic.black};
    height: 1px;
    width: 100%;
  `
);
