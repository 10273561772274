import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { EOxStoryVariant } from "./OxStory";
import { OxContainer } from "src/components/OxContainer";
import { OxImage } from "src/components/OxImage";
import { OxLinkWithLine } from "src/components/OxLinkWithLine";
import { ESizes } from "src/config/enums";
import { OxAutoScrollImages } from "src/components/OxAutoScrollImages";
import { TBasicTheme } from "src/types/theme.types";

export const Container = styled(OxContainer)<{
  variant: EOxStoryVariant;
}>(({ theme, variant }) => {
  const commonStyles = css`
    display: flex;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
  `;

  let variantStyles;
  switch (variant) {
    case EOxStoryVariant.ImageRight:
      variantStyles = css`
        ${theme.breakpoints.up("s")} {
          flex-direction: row-reverse;
        }
      `;
      break;
    case EOxStoryVariant.ImageLeft:
      variantStyles = css`
        ${theme.breakpoints.up("s")} {
          flex-direction: row;
        }
      `;
      break;
  }

  return css`
    ${commonStyles};
    ${variantStyles};
  `;
});

export const ContentWrapper = styled.div<{
  variant: EOxStoryVariant;
  $fullBleedContent?: boolean;
  isLast?: boolean;
}>(
  ({ theme, variant, $fullBleedContent, isLast }) => css`
    width: 100%;

    ${theme.breakpoints.up("s")} {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    ${fluidSizing([
      {
        prop: "padding-left",
        values:
          $fullBleedContent && variant === EOxStoryVariant.ImageRight
            ? [ESizes.GutterXS, null, null]
            : [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM],
      },
      {
        prop: "padding-right",
        values:
          $fullBleedContent && variant === EOxStoryVariant.ImageLeft
            ? [ESizes.GutterXS, null, null]
            : [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM],
      },
    ])}

    ${$fullBleedContent &&
    !isLast &&
    css`
      ${theme.breakpoints.only("xs")} {
        margin-bottom: ${toVw(20, "xs")};
      }
    `}
  `
);

const sharedAssetStyles = (
  theme: TBasicTheme
  // $fullBleedContent?: boolean
) => css`
  ${theme.breakpoints.only("xs")} {
    width: 100%;
  }
  ${theme.breakpoints.up("s")} {
    width: 50%;
    height: auto;
  }
  ${fluidSizing([
    // { prop: "height", values: [300, $fullBleedContent ? 400 : 320, 400] },
    { prop: "margin-bottom", values: [20, null, null] },
  ])}
`;

export const AutoScrollAsset = styled(OxAutoScrollImages)<{
  $fullBleedContent?: boolean;
}>(({ theme, $fullBleedContent }) =>
  sharedAssetStyles(theme, $fullBleedContent)
);

export const Asset = styled(OxImage)<{
  $fullBleedContent?: boolean;
}>(({ theme, $fullBleedContent }) =>
  sharedAssetStyles(theme, $fullBleedContent)
);

export const Link = styled(OxLinkWithLine)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
  `
);

export const ImageWrapper = styled.div`
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;
