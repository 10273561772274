import React, { useContext, useRef } from "react";
import * as Styled from "src/components/OxBanner/OxBanner.styled";
import { LayoutContext } from "src/context/LayoutContext";
import { OxLink } from "src/components/OxLink";
import { Video } from "src/services/cms/cms.types";

export type TBannerItem = {
  titleArray: { sentence: string[] }[];
  subtitle?: { sentence: string[] }[];
  link?: string;
  video?: string | Video[];
  fluidImages?: any[];
  isOverlaidTextBlack?: boolean;
  eagerLoad?: boolean;
};

type TProps = TBannerItem & {
  componentIsReady: boolean;
};

export const OxBannerItem = ({
  fluidImages = [],
  titleArray = [],
  subtitle = [],
  link,
  video,
  isOverlaidTextBlack,
  componentIsReady,
  eagerLoad,
}: TProps): JSX.Element => {
  const layoutContext = useContext(LayoutContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hasVideo = Array.isArray(video) ? video.length > 0 : !!video;

  const unArrayedTitle = titleArray.map((l) => l.sentence.join(" ")).join(" ");

  const content = (
    <>
      {(video?.length ?? 0) > 0 && (
        <Styled.VideoPlayer
          src={video}
          ref={videoRef}
          addStopButton
          muted
          autoPlay
          playsInline
          loop
          fitVideo
          playIfOutOfView
          controls={false}
        />
      )}
      {fluidImages && (
        <Styled.Image
          alt={unArrayedTitle}
          fluidImages={fluidImages}
          eagerLoad={eagerLoad}
        />
      )}
      <Styled.Content
        isOverlaidTextBlack={isOverlaidTextBlack}
        hide={hasVideo && !videoRef?.current?.paused}
      >
        {titleArray.length > 0 && (
          <Styled.Title
            text={titleArray.map((line) => line.sentence)}
            animationInit={
              !layoutContext.websiteLoaderActive && componentIsReady
            }
            elementoverride={"h2"}
          />
        )}
        {subtitle.length > 0 && (
          <Styled.Sub
            text={subtitle.map((line) => line.sentence)}
            animationInit={
              !layoutContext.websiteLoaderActive && componentIsReady
            }
            elementoverride={"h1"}
          />
        )}
      </Styled.Content>
    </>
  );

  return <>{link ? <OxLink to={link}>{content}</OxLink> : content}</>;
};
